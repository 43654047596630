<template>
  <el-collapse v-model="activeNameOrder">
    <el-collapse-item
      v-for="(item, index) in chargeOrderList"
      :key="index"
      :class="chargeOrderList.length == 1? 'hideHeader' : ''"
      :title="'充电订单' + (index + 1)"
      :name="index"
    >
      <div class="parkInfo">
        <h2 class="parkingTitle">订单基本信息</h2>
        <div class="parkInfoDetail">
          <div class="infoItem">
            <span class="infoLabel">订单号</span><span>{{ item.chargingOrderId }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">用户手机号</span><span>{{ item.mobile }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">车牌号</span><span>{{ item.plateNumber }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">充电站</span><span>{{ item.stationName }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">开始充电时间</span><span>{{ item.startTime }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">结束充电时间</span><span>{{ item.endTime }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">充电时长</span><span>{{ item.chargeLast | timeFormat}}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">充电结束原因</span
            ><span>{{ item.endReason | endReasonDesc }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">电表总起值</span><span>{{ item.meterValueStart }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">电表总止值</span><span>{{ item.meterValueEnd }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">累计充电量</span><span>{{ item.totalPower }}kwh</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">车位号</span><span>{{ item.berthCode }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">桩名称</span><span>{{ item.equipmentName }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">枪名称</span><span>{{ item.connectorName }}</span>
          </div>
        </div>
        <div class="chargeRecordInfo">
          <div class="chargeRecordId">订单费用信息</div>
          <div class="parkInfoDetail">
            <div class="infoItem">
              <span class="infoLabel">订单应收金额</span><span>{{ item.startTime }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">总电费</span><span>{{ item.totalElecMoney / 100 }}元</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">总服务费</span
              ><span>{{ item.totalSeviceMoney / 100 }}元</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">实收金额</span><span>{{ item.startTime }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">支付方式</span><span>{{ item.payTypeStr }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">支付时间</span><span>{{ item.payTime }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">账户冻结金额</span><span>{{ item.startTime }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">返还解冻金额</span><span>{{ item.startTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 充电明细 -->
      <div v-if="item.chargingFeeDetails.length" class="parkInfo">
        <div class="parkingTitle">充电明细</div>
        <el-table :data="item.chargingFeeDetails" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
          <el-table-column
            align="center"
            :label="item.label"
            :prop="item.prop"
            :min-width="item.width"
            v-for="item in chargingFeeList"
            :key="item.value"
            :formatter="item.formatter"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
export default {
  props: {
    chargeOrderList: {
      type: Array,
      default: [],
    },
  },
  filters: {
    endReasonDesc(type) {
      let typeList = {
        0: "用户手动停止充电",
        1: "客户归属地运营商平台停止充电",
        2: "BMS 停止充电",
        3: "充电机设备故障",
        4: "连接器断开"
      };
      return typeList[type];
    },
    timeFormat(minutes) {
      if (minutes < 60) {
        return `${minutes}分钟`;
      } else if (minutes < 1440) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}小时 ${remainingMinutes}分钟`;
      } else {
        const days = Math.floor(minutes / 1440);
        const remainingHours = Math.floor((minutes % 1440) / 60);
        const remainingMinutes = minutes % 60;
        return `${days}天 ${remainingHours}小时 ${remainingMinutes}分钟`;
      }
    }
  },
  data() {
    return {
      activeNameOrder: 0,
      chargingFeeList: [
        {
          label: "开始时间",
          prop: "detailStartTime",
          width: "120",
        },
        {
          label: "结束时间",
          prop: "detailEndTime",
          width: "120",
        },
        {
          label: "时段电价",
          prop: "elecPrice",
          formatter: (row, column) => {
            if (row.elecPrice) {
              return row.elecPrice + "元/度";
            } else {
              return "-";
            }
          },
        },
        {
          label: "时段服务费单价",
          prop: "sevicePrice",
          width: "100",
          formatter: (row, column) => {
            if (row.sevicePrice) {
              return row.sevicePrice + "元/度";
            } else {
              return "-";
            }
          },
        },
        {
          label: "时段充电量",
          prop: "detailPower",
          formatter: (row, column) => {
            if (row.detailPower) {
              return row.detailPower + "度";
            } else {
              return "-";
            }
          },
        },
        {
          label: "时段电费",
          prop: "detailElecMoney",
          formatter: (row, column) => {
            if (row.detailElecMoney) {
              return row.detailElecMoney + "元";
            } else {
              return "-";
            }
          },
        },
        {
          label: "时段服务费",
          prop: "detailSeviceMoney",
          formatter: (row, column) => {
            if (row.detailSeviceMoney) {
              return row.detailSeviceMoney + "元";
            } else {
              return "-";
            }
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang="stylus">
.el-collapse-item.hideHeader
  /deep/.el-collapse-item__header
          display: none;
</style>

