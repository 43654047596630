<template>
  <el-collapse v-model="activeNameRecord">
    <el-collapse-item
      v-for="(item, index) in chargeRecordList"
      :key="index"
      :class="chargeRecordList.length == 1? 'hideHeader' : ''"
      :title="'违规记录' + (index + 1)"
      :name="index"
    >
      <div class="parkInfo">
        <h2 class="parkingTitle">违规信息</h2>
        <div class="parkInfoDetail">
          <div class="infoItem">
            <span class="infoLabel">充电站</span><span>{{ item.stationName }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">停车泊位号</span><span>{{ item.berthCode }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">充电桩</span><span>{{ item.equipmentName }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">充电枪</span><span>{{ item.connectorName }}</span>
          </div>
          <div class="infoItem red">
            <span class="infoLabel">违规类型</span
            ><span>{{ item.violationType | violationTypeName }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">违规状态</span
            ><span>{{ item.violationStatus | violationStatusName }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">事件发生时间</span><span>{{ item.happenTime }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">记录创建时间</span><span>{{ item.createdTime }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">记录更新时间</span><span>{{ item.updatedTime }}</span>
          </div>
          <div class="infoItem">
            <span class="infoLabel">未归枪持续时长</span><span>{{ item.unReturnGunTime | timeFormat}}</span>
          </div>
        </div>
        <div v-if="!item.images" class="imgList">
          <h2>违规取证图片</h2>
          <img
            v-for="(
              value, idx
            ) in 'http://ace-image-1325768085.cos.ap-beijing.myqcloud.com/busy/20241024/HW012311231411040108/a27d0c92a32f00e58800e7c934e6aafb.jpg,http://ace-image-1325768085.cos.ap-beijing.myqcloud.com/busy/20241024/HW012311231411040108/a27d0c92a32f00e58800e7c934e6aafb.jpg'.split(
              ','
            )"
            :key="idx"
            class="picItem"
            :src="value"
            @click="showBigPic(value)"
          />
        </div>
      </div>
      <!-- 关联充电信息 -->
      <div v-if="item.chargeRecords.length" class="parkInfo">
        <div class="parkingTitle">关联充电信息</div>
        <div class="chargeRecordInfo" v-for="(value, idx) in item.chargeRecords" :key="idx">
          <div class="chargeRecordId">充电订单3232332323</div>
          <div class="parkInfoDetail">
            <div class="infoItem">
              <span class="infoLabel">电站名称</span><span>{{ value.stationName }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">充电枪名称</span><span>{{ value.connectorName }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">充电开始时间</span><span>{{ value.startTime }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">充电结束时间</span><span>{{ value.endTime }}</span>
            </div>
            <div class="infoItem">
              <span class="infoLabel">充电时长</span><span>{{ value.chargeLast | timeFormat}}分钟</span>
            </div>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script>
export default {
  props: {
    chargeRecordList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      activeNameRecord: 0,
    };
  },
  filters: {
    violationTypeName(parkType) {
      let parkTypeList = {
        1: "未归枪",
        2: "占位未充电",
        3: "超时占位",
      };
      return parkTypeList[parkType];
    },
    violationStatusName(parkType) {
      let parkTypeList = {
        1: "进行中",
        2: "已完结",
      };
      return parkTypeList[parkType];
    },
    timeFormat(minutes) {
      if (minutes < 60) {
        return `${minutes}分钟`;
      } else if (minutes < 1440) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}小时 ${remainingMinutes}分钟`;
      } else {
        const days = Math.floor(minutes / 1440);
        const remainingHours = Math.floor((minutes % 1440) / 60);
        const remainingMinutes = minutes % 60;
        return `${days}天 ${remainingHours}小时 ${remainingMinutes}分钟`;
      }
    }
  },
  methods: {
    showBigPic(url) {
      this.$emit("showBigPic", url);
    },
  },

}
</script>
<style scoped lang="stylus">
.el-collapse-item.hideHeader
  /deep/.el-collapse-item__header
          display: none;
</style>
