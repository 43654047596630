var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-collapse",
    {
      model: {
        value: _vm.activeNameOrder,
        callback: function ($$v) {
          _vm.activeNameOrder = $$v
        },
        expression: "activeNameOrder",
      },
    },
    _vm._l(_vm.chargeOrderList, function (item, index) {
      return _c(
        "el-collapse-item",
        {
          key: index,
          class: _vm.chargeOrderList.length == 1 ? "hideHeader" : "",
          attrs: { title: "充电订单" + (index + 1), name: index },
        },
        [
          _c("div", { staticClass: "parkInfo" }, [
            _c("h2", { staticClass: "parkingTitle" }, [_vm._v("订单基本信息")]),
            _c("div", { staticClass: "parkInfoDetail" }, [
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("订单号")]),
                _c("span", [_vm._v(_vm._s(item.chargingOrderId))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("用户手机号"),
                ]),
                _c("span", [_vm._v(_vm._s(item.mobile))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("车牌号")]),
                _c("span", [_vm._v(_vm._s(item.plateNumber))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("充电站")]),
                _c("span", [_vm._v(_vm._s(item.stationName))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("开始充电时间"),
                ]),
                _c("span", [_vm._v(_vm._s(item.startTime))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("结束充电时间"),
                ]),
                _c("span", [_vm._v(_vm._s(item.endTime))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("充电时长")]),
                _c("span", [
                  _vm._v(_vm._s(_vm._f("timeFormat")(item.chargeLast))),
                ]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("充电结束原因"),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm._f("endReasonDesc")(item.endReason))),
                ]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("电表总起值"),
                ]),
                _c("span", [_vm._v(_vm._s(item.meterValueStart))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("电表总止值"),
                ]),
                _c("span", [_vm._v(_vm._s(item.meterValueEnd))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [
                  _vm._v("累计充电量"),
                ]),
                _c("span", [_vm._v(_vm._s(item.totalPower) + "kwh")]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("车位号")]),
                _c("span", [_vm._v(_vm._s(item.berthCode))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("桩名称")]),
                _c("span", [_vm._v(_vm._s(item.equipmentName))]),
              ]),
              _c("div", { staticClass: "infoItem" }, [
                _c("span", { staticClass: "infoLabel" }, [_vm._v("枪名称")]),
                _c("span", [_vm._v(_vm._s(item.connectorName))]),
              ]),
            ]),
            _c("div", { staticClass: "chargeRecordInfo" }, [
              _c("div", { staticClass: "chargeRecordId" }, [
                _vm._v("订单费用信息"),
              ]),
              _c("div", { staticClass: "parkInfoDetail" }, [
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [
                    _vm._v("订单应收金额"),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.startTime))]),
                ]),
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [_vm._v("总电费")]),
                  _c("span", [
                    _vm._v(_vm._s(item.totalElecMoney / 100) + "元"),
                  ]),
                ]),
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [
                    _vm._v("总服务费"),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(item.totalSeviceMoney / 100) + "元"),
                  ]),
                ]),
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [
                    _vm._v("实收金额"),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.startTime))]),
                ]),
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [
                    _vm._v("支付方式"),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.payTypeStr))]),
                ]),
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [
                    _vm._v("支付时间"),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.payTime))]),
                ]),
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [
                    _vm._v("账户冻结金额"),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.startTime))]),
                ]),
                _c("div", { staticClass: "infoItem" }, [
                  _c("span", { staticClass: "infoLabel" }, [
                    _vm._v("返还解冻金额"),
                  ]),
                  _c("span", [_vm._v(_vm._s(item.startTime))]),
                ]),
              ]),
            ]),
          ]),
          item.chargingFeeDetails.length
            ? _c(
                "div",
                { staticClass: "parkInfo" },
                [
                  _c("div", { staticClass: "parkingTitle" }, [
                    _vm._v("充电明细"),
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: item.chargingFeeDetails },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: _vm.$t("list.index"),
                          width: "70",
                        },
                      }),
                      _vm._l(_vm.chargingFeeList, function (item) {
                        return _c("el-table-column", {
                          key: item.value,
                          attrs: {
                            align: "center",
                            label: item.label,
                            prop: item.prop,
                            "min-width": item.width,
                            formatter: item.formatter,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }